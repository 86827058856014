import React from "react";
import ReactDOM from "react-dom/client";
import { UserbackProvider } from "@userback/react";
import TagManager from "react-gtm-module";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "react-international-phone/style.css";
import "./custom.scss";

// const tagManagerArgs = {
//   gtmId: "GTM-W4RKSBFV",
// };

const tagManagerArgs = {
  gtmId: "GTM-P3MTHB4G",
};

TagManager.initialize(tagManagerArgs);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  // <React.StrictMode>
  //   <App />
  // </React.StrictMode>
  <UserbackProvider token="P-hNlTWNHkXfErdpLH6CP39PvXI">
    <App />
  </UserbackProvider>
);

reportWebVitals();
